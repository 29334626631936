.footer {
  background-color: #111B21; /* Color de fondo oscuro */
  color: #f0f0f0; /* Color de texto claro */
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #333; /* Color de borde oscuro */
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footerLinks {
  list-style: none;
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 10px 0 0 0;
}

.footerLink {
  color: #b3b3b3; 
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footerLink:hover {
  color: #ffffff; 
}

@media (max-width: 600px) {
  .footerContent {
    padding: 0 10px;
  }

  .footerLinks {
    flex-direction: column;
    align-items: center;
  }

  .footerLink {
    font-size: 1rem;
  }
}

