/* src/components/Header/Header.module.css */

.header {
  background-color:#F8F9FA;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  text-decoration: none; 
 
}
.logoContainer {
  display: flex;
  align-items: center; 
}

.navbar-logo {
  margin-right: 10px; 
}


.logo h2 {
  color: var(--primary-color);
  margin: 0;
  font-size: 1.5rem;
  text-decoration: none;
}

.navList {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
}

.navLink {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1rem;
  transition: color var(--transition-speed) ease;
}

.navLink:hover {
  color: var(--primary-hover);
}

.userInfo {
  display: flex;
  align-items: center;
}

.userEmail {
  color: var(--secondary-text-color);
  font-size: 1rem;
}

.logoutButton {
  background-color: var(--danger-color);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.logoutButton:hover {
  background-color: var(--danger-hover);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .navList {
    flex-direction: column;
    width: 100%;
  }

  .navLink {
    padding: 10px 0;
    width: 100%;
    text-align: left;
  }

  .logoutButton {
    width: 100%;
    text-align: left;
  }
}
