/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');



:root {
  --font-family: 'Poppins';
}

:root {
  --primary-color: #FA1562;
  --primary-hover: #E0144C;
  --background-color: #f8f9fa;
  --card-background: #ffffff;
  --border-color: #ddd;
  --font-family: 'Poppins';
  --border-radius: 8px;
  --transition-speed: 0.3s;
  --text-color: #333;
  --secondary-text-color: #555;
  --secondary-color: #0E6AAF;
  --secondary-hover: #0E6AAF;
  --danger-color: #FA1562;
  --danger-hover: #d32f2f;
}



[data-theme="dark"] {
  --primary-color: #e0144c;
  --primary-hover: #c1123d;
  --background-color: #121212;
  --card-background: #1e1e1e;
  --border-color: #333;
  --text-color: #f5f5f5;
  --secondary-text-color: #aaa;
}

body, html {
  font-family: var(--font-family);  /* Aplicar la fuente globalmente */
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, span, a, button, input, textarea {
  font-family: var(--font-family); /* Asegurar que los elementos comunes también tengan la fuente */
}
h3 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

button {
  border-radius: var(--border-radius);
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 12px 20px;
  border-radius: var(--border-radius);
  margin-top: 20px;
  font-size: 1rem;
  animation: fadeIn 0.5s ease;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 12px 20px;
  border-radius: var(--border-radius);
  margin-top: 20px;
  font-size: 1rem;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 1.5rem;
  color: var(--primary-color);
}



