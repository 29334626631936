// 
// Buttons
// 

//- Custom XL button styling

.btn-xl {
    padding: 1.25rem 2.25rem;
    font-size: 0.85rem;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 10rem;
}

/* Estilos para el Botón Flotante de WhatsApp */
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366; /* Color verde de WhatsApp */
    color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
}

.whatsapp-float:hover {
    background-color: #128C7E; /* Verde oscuro al pasar el cursor */
    transform: scale(1.1);
}

.whatsapp-float i {
    margin: 0;
}

/* Estilos personalizados para el Botón de WhatsApp */
.btn-masinfo {
    background-color: #25d366; /* Color verde de WhatsApp */
    border-color: #25d366;
    color: white;
}

.btn-masinfo:hover {
    background-color: #128c7e; /* Verde oscuro al pasar el cursor */
    border-color: #128c7e;
}


