/* src/components/ThemeToggle/ThemeToggle.module.css */


  .themeToggle {
    color: var(--primary-color); /* Color del botón según el tema */
    transition: color 0.3s ease;
  }
  
  .themeToggle:hover {
    color: var(--hover-color); /* Color al hacer hover */
  }
  
  