// 
// Typography
// 
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


// Sobrescribir la tipografía sans-serif con Poppins
$font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Si prefieres mantener Merriweather para serif
$font-family-serif: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Hacer que la fuente base del proyecto sea Poppins
$font-family-base: $font-family-sans-serif;

// Usar Poppins para los encabezados
$headings-font-family: $font-family-sans-serif;

// Usar Poppins también para los botones
$btn-font-family: $font-family-sans-serif;
